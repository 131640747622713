import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from 'react'
import styles from './Header.module.scss'
import config from '../../data'

const Header = ({ mode, projectPage, siteTitle }) => {
  const [isIndex, setIsIndex] = useState(false)

  
  useEffect(() => {
    const pathname = typeof window !== 'undefined' ? window.location.pathname : ''
    setIsIndex(((!pathname.includes('/inquire/')) && (!pathname.includes('/information/'))))
  })

  return (
    <header className={projectPage === true ? styles.projectHeader : styles.header}>
      <nav className={styles.nav}>
        <ul className={isIndex ? styles.indexList : null}>
          {config.navItems.map((item, index) => (
            <li key={index}>
              <Link
                activeStyle={{ textDecoration: "underline" }}
                to={item.uri.length > 1 ? `${item.uri}/` : '/'}
              >
                  {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <Link className={styles.logo} to='/'>
        <h1>{siteTitle}</h1>
        <svg width="15" height="24" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path 
            d="M16.8937 12.8L9.66875 9.8V0H7.225V8.8L0 5.9V8.5L7.225 11.4V16.8L0 19.6V22.2L7.225 19.2V28H9.775V18.1L17 15.1V12.8H16.8937ZM9.66875 15.7V12.3L13.9188 14L9.66875 15.7Z" 
            fill={mode === 'light' ? '#000000' : '#ffffff'} 
          />
        </svg>
      </Link>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
