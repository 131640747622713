/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { window } from 'browser-monads'
import cookie from 'react-cookies'


import Footer from '../Footer'
import Header from '../Header'
import './Layout.scss'

const Layout = ({ children, className, projectPage, style, windowHeight }) => {
  const preferredTheme = () => {    
    if (cookie.load('theme') !== undefined) {
      return cookie.load('theme');
    }

    if (window.matchMedia('(prefers-color-scheme: dark)').matches === true) {
      return 'dark';
    } 

    return 'light';
  }

  const [mode, setMode] = useState(preferredTheme());

  const handleSetMode = (mode) => {
    setMode(mode);
    cookie.save('theme', mode);
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div 
      className={`${className !== undefined ? className : ''} ${mode === 'light' ? 'wrapper' : 'wrapper wrapper--dark'}`}
      style={{ 'height': windowHeight, ...style}}
    >
      <div className='container' style={{'height': windowHeight}}>
        <a
          className='skipLink'
          href="#mainContent"
        >
          Skip to main content
        </a>
        <Header 
          mode={mode}
          projectPage={projectPage}
          siteTitle={data.site.siteMetadata.title} 
        />
        <main>{children}</main>
        <Footer 
          mode={mode}
          setMode={handleSetMode} 
        />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
