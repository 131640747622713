const config = {
  menuItems: [
    {
      name: 'Clinique',
      uri: '/',
      year: '2018'
    },
    {
      name: 'The New School',
      uri: '/',
      year: '2017'
    },
    {
      name: 'NEJM',
      uri: '/',
      year: '2016'
    },
    {
      name: 'VSCO',
      uri: '/',
      year: '2015'
    },
    {
      name: 'Disorder',
      uri: '/',
      year: '2012'
    },
    {
      name: 'Abraham’s Sacrifice',
      uri: '/abrahams-sacrifice',
      year: '2014'
    },
    {
      name: 'Transparency',
      uri: '/',
      year: '2015'
    },
    {
      name: 'Defend Emo',
      uri: '/',
      year: '2018'
    },
    {
      name: 'Photography',
      uri: '/',
      year: '—'
    }
  ],
  navItems: [
    {
      name: 'Index',
      uri: '/',
    },
    {
      name: 'Inquire',
      uri: '/inquire',
    },
    {
      name: 'Information',
      uri: '/information',
    },
  ]
}

export default config