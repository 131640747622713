import React from 'react';
import styles from './Footer.module.scss'


const Footer = ({ mode, setMode }) => (
  <footer className={styles.footer}>
    <menu className={styles.menu}>
      <ul>
        <li>
          <button 
            className={mode === 'light' ? styles.active : styles.button}
            onClick={() => setMode('light')}>White</button>
        </li>
        <li>
          <button 
            className={mode === 'dark' ? styles.active : styles.button}
            onClick={() => setMode('dark')}>Black</button>
        </li>
      </ul>
    </menu>
  </footer>
)

export default Footer
